<template>
	<w-layout v-if="$vuetify.breakpoint.mdAndDown" column style="width: 100%">
		<w-flex>
			<DocumentsToolbarSearch v-model="currentFolder" />
		</w-flex>
		<v-divider />
		<w-flex>
			<DocumentsToolbarActions
				v-model="model"
				:selected-folder="currentFolder"
				:show-details="showDetails"
				@selection-changed="currentFolder = $event"
				@do-show-details="doShowDetails"
			/>
		</w-flex>
		<w-flex>
			<DocumentsToolbarBreadcrumbs v-model="currentFolder" />
		</w-flex>
	</w-layout>
	<w-layout v-else-if="$vuetify.breakpoint.lg" row align-center justify-space-between style="width: 100%">
		<w-flex xs6 fill-height>
			<w-layout row align-center justify-start fill-height style="width: 100%">
				<w-flex x5 fill-height>
					<DocumentsToolbarBreadcrumbs v-model="currentFolder" />
				</w-flex>
				<w-flex x7 fill-height>
					<DocumentsToolbarSearch v-model="currentFolder" />
				</w-flex>
			</w-layout>
		</w-flex>
		<w-flex shrink fill-height>
			<DocumentsToolbarActions
				v-model="model"
				:selected-folder="currentFolder"
				:show-details="showDetails"
				@selection-changed="currentFolder = $event"
				@do-show-details="doShowDetails"
			/>
		</w-flex>
	</w-layout>
	<w-layout v-else row align-center justify-center style="width: 100%">
		<w-flex xs3 fill-height>
			<DocumentsToolbarBreadcrumbs v-model="currentFolder" />
		</w-flex>
		<w-flex xs4 fill-height>
			<DocumentsToolbarSearch v-model="currentFolder" />
		</w-flex>
		<v-spacer />
		<w-flex xs5 fill-height>
			<w-layout row align-center justify-end fill-height>
				<w-flex shrink>
					<DocumentsToolbarActions
						v-model="model"
						:selected-folder="currentFolder"
						:show-details="showDetails"
						@selection-changed="currentFolder = $event"
						@do-show-details="doShowDetails"
					/>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'DocumentsToolbar',
	components: {
		DocumentsToolbarBreadcrumbs: () => ({
			component: import('@/components/Documents/Toolbar/DocumentsToolbarBreadcrumbs')
		}),
		DocumentsToolbarSearch: () => ({
			component: import('@/components/Documents/Toolbar/DocumentsToolbarSearch')
		}),
		DocumentsToolbarActions: () => ({
			component: import('@/components/Documents/Toolbar/DocumentsToolbarActions')
		})
	},
	props: {
		selectedFolder: {
			type: Object,
			required: false,
			default: null
		},
		showDetails: {
			type: Boolean,
			required: false,
			default: true
		},
		value: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		currentFolder: {
			get: function () {
				return this.selectedFolder
			},
			set: function (value) {
				if (value) {
					this.$emit('selection-changed', value)
				}
			}
		}
	},
	methods: {
		doShowDetails: function (value) {
			this.$emit('show-details', value)
		}
	}
}
</script>
